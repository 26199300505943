/* globals CKEDITOR */

var loadCKEDITOR = function(callback) {
  if (typeof CKEDITOR === 'undefined') {
    $.getScript('https://cdn.ckeditor.com/4.10.1/full/ckeditor.js', function() {
      CKEDITOR.disableAutoInline = true;
      callback();
    });
  } else {
    callback();
  }
};

var textEditorAutoUpdate  = function(editor) {
  editor.on( 'change', function( evt ) {
    evt.editor.updateElement();
  });
};

var fullTextEditor = function(element, height) {
  height = typeof height !== 'undefined' ? height : 250;
  textEditorAutoUpdate(CKEDITOR.replace(element, {
    height: height
  }));
};

var basicTextEditor = function(element, height) {
  height = typeof height !== 'undefined' ? height : 250;
  textEditorAutoUpdate(CKEDITOR.replace(element, {
    height: height,
    toolbarGroups: [
      {
        name: 'basicstyles',
        groups: ['basicstyles', 'cleanup']
      },
      {
        name: 'paragraph',
        groups: ['list', 'align']
      },
      {
        name: 'styles',
        groups: ['styles']
      },
      {
        name: 'colors',
        groups: ['colors']
      },
      '/',
      {
        name: 'links',
        groups: ['links']
      },
      {
        name: 'insert',
        groups: ['insert']
      },
      {
        name: 'undo',
        groups: ['undo']
      },
      {
        name: 'tools',
        groups: ['tools']
      },
      {
        name: 'document',
        groups: ['mode', 'document', 'doctools']
      },
      {
        name: 'others',
        groups: ['others']
      }
    ],
    removeButtons: 'Font,Styles,ShowBlocks,Save,NewPage,Templates,Superscript,Subscript,Flash,Smiley,Iframe,PageBreak'
  }));
};

var modalTextEditor = function(element, height) {
  height = typeof height !== 'undefined' ? height : 250;
  textEditorAutoUpdate(CKEDITOR.replace(element, {
    height: height,
    toolbarGroups: [
      {
        name: 'colors',
        groups: ['colors']
      },
      {
        name: 'basicstyles',
        groups: ['basicstyles', 'cleanup']
      },
      {
        name: 'paragraph',
        groups: ['list', 'align']
      },
      {
        name: 'links',
        groups: ['links']
      },
      {
        name: 'undo',
        groups: ['undo']
      },
      '/',
      {
        name: 'styles',
        groups: ['styles']
      },
      {
        name: 'insert',
        groups: ['insert']
      },
      {
        name: 'tools',
        groups: ['tools']
      },
      {
        name: 'document',
        groups: ['mode', 'document', 'doctools']
      },
      {
        name: 'others',
        groups: ['others']
      }
    ],
    removeButtons: 'Font,Styles,ShowBlocks,Save,NewPage,Preview,Print,Templates,Superscript,Subscript,Strike,Flash,Smiley,Iframe,PageBreak'
  }));
};

var inlineTextEditor = function(element) {
  textEditorAutoUpdate(CKEDITOR.inline(element, {
    toolbarGroups: [
      {
        name: 'colors',
        groups: ['colors']
      },
      {
        name: 'basicstyles',
        groups: ['basicstyles', 'cleanup']
      },
      {
        name: 'paragraph',
        groups: ['list', 'align']
      },
      {
        name: 'links',
        groups: ['links']
      },
      {
        name: 'styles',
        groups: ['styles']
      }
    ],
    removeButtons: 'Font,Styles,ShowBlocks,Save,NewPage,Preview,Print,Templates,Superscript,Subscript,Strike,Flash,Smiley,Iframe,PageBreak'
  }));
};

$(function() {
  var default_height = 250;
  $('.text-editor').each(function() {
    var $this = $(this);
    var height = default_height;
    if ($this.attr('data-editor-height')) {
      height = parseInt($this.attr('data-editor-height'));
    }
    loadCKEDITOR(function() {
      basicTextEditor($this[0], height);
    });
  });
  $('.text-editor-full').each(function() {
    var $this = $(this);
    var height = default_height;
    if ($this.attr('data-editor-height')) {
      height = parseInt($this.attr('data-editor-height'));
    }
    loadCKEDITOR(function() {
      fullTextEditor($this[0], height);
    });
  });
  $('.text-editor-modal').each(function() {
    var $this = $(this);
    var height = default_height;
    if ($this.attr('data-editor-height')) {
      height = parseInt($this.attr('data-editor-height'));
    }
    loadCKEDITOR(function() {
      modalTextEditor($this[0], height);
    });
  });
  $('.text-editor-inline').each(function() {
    loadCKEDITOR(function() {
      inlineTextEditor($(this)[0]);
    });
  });
});
