import { getJSON, deleteJSON } from './duxfront/plugins/dux-utils';

global.getJSON = getJSON;
global.deleteJSON = deleteJSON;

// ********************* ADDRESS UTILS ********************

export function getAddress(cep, success, error) {
  getJSON(
    `https://viacep.com.br/ws/${cep}/json/`,
    {},
    success,
    error,
  );
};

global.getAddress = getAddress;

// ********************* MASK UTILS ********************

export function cpfcnpjMask(el) {
  let opts = null;
  const masks = ['000.000.000-009', '00.000.000/0000-00'];
  const callback = function () {
    let mask = masks[0];
    let validation = 'required & regex:^\\d{3}\\.\\d{3}\\.\\d{3}-\\d{2}$:CPF inválido';
    if (el.val().length > 14) {
      mask = masks[1];
      validation = 'required & regex:^\\d{2}\\.\\d{3}\\.\\d{3}\\/\\d{4}-\\d{2}$:CNPJ inválido';
    }
    el.mask(mask, opts);
    if (el.attr('data-validate')) {
      if (el.attr('data-validate') !== validation) {
        el.attr('data-validate', validation);
        el.duxValidate();
      }
    }
  };
  opts = {
    onKeyPress: callback,
  };
  callback();
};

global.cpfcnpjMask = cpfcnpjMask
