/* globals $ */

import AOS from './vendor/aos.min';
import {} from './vendor/background-video';
import {} from './vendor/headroom';
import {} from './vendor/imagesloaded.min';
import {} from './vendor/magnific-popup';
import {} from './vendor/modernizr';
import {} from './vendor/owl-carousel.min';
import {} from './vendor/sticky-sidebar';
import Typed from './vendor/typed.min';

export const DuxstrapLite = {
  init() {
    DuxstrapLite.AOS();
    DuxstrapLite.Header();
    DuxstrapLite.Lightbox();
    DuxstrapLite.OwlCarousel();
    DuxstrapLite.StickySidebar();
    DuxstrapLite.Typed();
  },

  AOS() {
    AOS.init({
      duration: 800,
      anchorPlacement: 'center-bottom',
      once: true,
    });
  },

  Header() {
    $('header').headroom({
      tolerance: {
        down: 15,
        up: 40,
      },
    });

    $('#header-navbar').on('show.bs.collapse', () => {
      $('header').data('headroom').tolerance = {
        down: 100000,
        up: 100000,
      };
    });

    $('#header-navbar').on('hide.bs.collapse', () => {
      $('header').data('headroom').tolerance = {
        down: 15,
        up: 40,
      };
    });
  },

  Lightbox() {
    $('.lightbox').magnificPopup({
      type: 'image',
      closeOnContentClick: true,
      closeBtnInside: false,
      fixedContentPos: true,
      mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
      image: {
        verticalFit: true,
      },
    });

    $('.gallery').each(function () {
      $(this).magnificPopup({
        delegate: '.photo > a', // the selector for gallery item
        type: 'image',
        mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
        gallery: {
          enabled: true,
        },
      });
    });

    $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
      disableOn: 700,
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
    });
  },

  OwlCarousel() {
    $('.owl-carousel').each(function () {
      const slider = $(this);
      const items = slider.data('items') || [1, 1, 1];
      const margin = slider.data('margin');
      const loop = slider.data('loop');
      const nav = slider.data('nav');
      const dots = slider.data('dots');
      const center = slider.data('center');
      const autoplay = slider.data('autoplay');
      const autoplaySpeed = slider.data('autoplay-speed');
      const rtl = slider.data('rtl');
      const autoheight = slider.data('autoheight');

      slider.owlCarousel({
        nav: nav || false,
        loop: loop || false,
        dots: dots || false,
        center: center || false,
        autoplay: autoplay || false,
        autoHeight: autoheight || false,
        rtl: rtl || false,
        margin: margin || 0,
        autoplayTimeout: autoplaySpeed || 3000,
        autoplaySpeed: 400,
        autoplayHoverPause: true,
        responsive: {
          0: {
            items: items[2] || 1,
          },
          576: {
            items: items[1] || 1,
          },
          1200: {
            items: items[0] || 1,
          },
        },
      });
    });
  },

  StickySidebar() {
    $('.sticky-sidebar').each(function () {
      const $this = $(this);
      const options = {
        topSpacing: 15,
      };
      if ($this.attr('data-sticky')) {
        options.topSpacing = parseInt($this.attr('data-sticky'), 10);
      }
      $this.stickySidebar(options);
    });
  },

  Typed() {
    $('[data-typed]').each(function () {
      const $this = $(this);

      new Typed(`#${$this.attr('id')}`, {
        stringsElement: `#${$this.attr('data-typed')}`,
        typeSpeed: 75,
        backSpeed: 60,
        backDelay: 1500,
        startDelay: 150,
        loop: true,
      });
    });
  },
};
