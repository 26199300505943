/* globals isInteger */

var duxValidate = (function($) {

  var locale = typeof $('html').attr('lang') == 'undefined' ? 'en' : $('html').attr('lang');

  var rules = {
    'default': function(input, params) {
      return {
        valid: true,
        error: {
          'en': params[0] || 'default rule'
        }
      };
    },
    'min': function(input, params) {
      return {
        valid: input.length >= parseInt(params[0]) || input.length === 0,
        error: {
          'en': params[1] || ('Minimum of ' + params[0] + ' characters'),
          'pt-br': params[1] || ('Mínimo de ' + params[0] + ' caracateres')
        }
      };
    },
    'max': function(input, params) {
      return {
        valid: input.length <= parseInt(params[0]) || input.length === 0,
        error: {
          'en': params[1] || ('Maximum of ' + params[0] + ' characters'),
          'pt-br': params[1] || ('Máximo de ' + params[0] + ' caracateres')
        }
      };
    },
    'email': function(input, params) {
      return {
        valid: (
          new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) // eslint-disable-line
        ).test(input) || input.length === 0,
        error: {
          'en': params[0] || 'Invalid e-mail address format',
          'pt-br': params[0] || 'Formato de e-mail inválido'
        }
      };
    },
    'required': function(input, params) {
      var result = input?.length && input.length > 0;
      if (typeof input === 'boolean') {
        result = input;
      }
      return {
        valid: result,
        error: {
          'en': params[0] || 'Required',
          'pt-br': params[0] || 'Necessário'
        }
      };
    },
    'url': function(input, params) {
      return {
        valid: (
          new RegExp(/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i) // eslint-disable-line
        ).test(input) || input.length === 0,
        error: {
          'en': params[0] || 'Invalid url format',
          'pt-br': params[0] || 'Formato de url inválido'
        }
      };
    },
    'integer': function(input, params) {
      return {
        valid: isInteger(Number(input)) || input.length === 0,
        error: {
          'en': params[0] || 'Must be an integer value',
          'pt-br': params[0] || 'Deve ser um número inteiro'
        }
      };
    },
    'numeric': function(input, params) {
      return {
        valid: isFinite(Number(input)) || input.length === 0,
        error: {
          'en': params[0] || 'Must be a numeric value',
          'pt-br': params[0] || 'Deve ser um valor numérico'
        }
      };
    },
    'alpha': function(input, params) {
      return {
        valid: String(input) && (new RegExp(/^[A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]+$/i).test(input)) ||
          input.length === 0,
        error: {
          'en': params[0] || 'Must have only letters',
          'pt-br': params[0] || 'Deve conter apenas letras'
        }
      };
    },
    'alphanum': function(input, params) {
      return {
        valid: (new RegExp(/^[a-z0-9]+$/i).test(input)) || input.length === 0,
        error: {
          'en': params[0] || 'Must have only alphanumeric characters',
          'pt-br': params[0] || 'Deve conter apenas caracateres alfanuméricos'
        }
      };
    },
    'regex': function(input, params) {
      return {
        valid: (new RegExp(params[0]).test(input)) || input.length === 0,
        error: {
          'en': params[1]
        }
      };
    },
    'matches': function(input, params) {
      return {
        valid: (input === $(params[0]).val()) || input.length === 0,
        error: {
          'en': params[1]
        }
      };
    }
  };

  var execRule = function(name, input, params) {
    if (!(name in rules)) {
      name = 'default';
    }
    return rules[name](input, params);
  };

  var errorMessage = function(error) {
    if (locale in error) {
      return error[locale];
    }
    return error.en;
  };

  var validate = function($this, input) {

    var result = true;
    var errors = '';
    var formgroup = $this.data('dux-validate-formgroup');
    var input_rules = $this.data('dux-validate-rules');

    for (var i in input_rules) {
      var rule = input_rules[i];
      var rule_result = execRule(rule.name, input, rule.params);
      if (!rule_result.valid) {
        result = false;
        errors = errors + errorMessage(rule_result.error) + '. ';
      }
    }

    if (result) {
      $this.addClass('is-valid');
      $this.removeClass('is-invalid');
    } else {
      $this.addClass('is-invalid');
      $this.removeClass('is-valid');
      formgroup.find('.invalid-feedback').html(errors);
    }

    return result;
  };

  $.fn.duxValidate = function() {
    var $this = $(this);
    if ($this.is('form')) {
      $this.submit(function(e) {
        if (!$this.duxValid()) {
          e.preventDefault();
          return false;
        } else {
          return true;
        }
      });
    } else {
      var formgroup = $this.parents('.form-group');
      var rules_str = $this.attr('data-validate').split('&');
      var rules = [];

      if ($this.attr('type') === 'checkbox') {
        formgroup = $this.parents('.form-check');
      }

      for (var rule in rules_str) {
        var params = rules_str[rule].trim().split(':');
        rules.push({
          name: params.splice(0, 1)[0].trim(),
          params: params
        });
      }

      if (formgroup.find('.invalid-feedback').length === 0) {
        formgroup.append('<div class="invalid-feedback"></div>');
      }
      if (formgroup.find('.valid-feedback').length === 0 && $this.attr('type') !== 'checkbox') {
        formgroup.append('<div class="valid-feedback"><i class="fa fa-check"></i></div>');
      }

      $this.data('dux-validate-rules', rules);
      $this.data('dux-validate-formgroup', formgroup);

      if ($this.is('select')) {
        $this.change(function() {
          validate($this, $this.children('option:selected').val());
        });
      } else {
        $this.bind('input', function() {
          setTimeout(function() {
            var input = null;
            if ($this.attr('type') === 'checkbox') {
              input = $this.prop('checked');
            } else {
              input = $this.val();
            }
            validate($this, input);
          }, 100);
        });
      }
    }
  };

  $.fn.duxValid = function() {
    var $this = $(this);
    var result = false;

    if ($this.is('form')) {
      result = true;
      $(this).find('[data-validate]').each(function() {
        if (!$(this).duxValid()) {
          result = false;
        }
      });
    } else if ($this.is('select')) {
      result = validate($this, $this.children('option:selected').val());
    } else {
      var input = null;
      if ($this.attr('type') === 'checkbox') {
        input = $this.prop('checked');
      } else {
        input = $this.val();
      }
      result = validate($this, input);
    }
    return result;
  };

  $(function() {
    $('[data-validate], .dux-validate').each(function() {
      $(this).duxValidate();
    });
  });

  return {
    setLocale: function(name) {
      locale = name;
    },

    addRule: function(name, rule_func) {
      rules[name] = rule_func;
    }
  };
})(jQuery);
