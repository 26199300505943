/* globals document window */
/* eslint-disable import/no-unresolved,import/no-webpack-loader-syntax */

import {} from './copyright';
import $ from 'expose-loader?exposes=$,jQuery!jquery';
import {} from 'jquery-ujs';
import {} from 'popper.js';
import {} from 'bootstrap';
import {} from './vendor/bootbox';
import {} from './vendor/cookies';
import { loadJqueryHelpers } from '../plugins/dux-utils';
import {} from './vendor/dux-validate';
import {} from './vendor/loading-overlay';
import {} from './vendor/mask';
import {} from './vendor/mustache';
import {} from './vendor/select2.min';
import {} from './vendor/slimscroll';

export const Duxstrap = {
  init() {
    Duxstrap.General();
    Duxstrap.Overlay();
    Duxstrap.Select2();
    Duxstrap.SlimScroll();
  },

  General() {
    loadJqueryHelpers();

    $('.dropdown-menu a.dropdown-toggle').on('click', function () {
      const a = $(this);
      if (!a.next().hasClass('show')) {
        a.parents('.dropdown-menu').first().find('.show').removeClass('show');
      }
      const $subMenu = a.next('.dropdown-menu');
      $subMenu.toggleClass('show');
      a.parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', () => {
        $('.dropdown-submenu .show').removeClass('show');
      });
      return false;
    });

    $('[data-selected]').each(function () {
      const $this = $(this);
      $this.find(`input[type=radio][value=${$this.attr('data-selected')}]`).prop('checked', true);
      $this.find(`input[type=checkbox][value=${$this.attr('data-selected')}]`).prop('checked', true);
      $this.find(`option[value=${$this.attr('data-selected')}]`).prop('checked', true);
    });

    setTimeout(() => {
      $(window).trigger('resize');
    }, 500);
    setTimeout(() => {
      $(window).trigger('resize');
    }, 1000);
    setTimeout(() => {
      $(window).trigger('resize');
    }, 2000);
  },

  Overlay() {
    $(document).ready(() => {
      $('.burger').click(function () {
        const a = $(this);
        a.toggleClass('clicked');
        $('body').toggleClass('overlay-active');
        $('.overlay-menu').toggleClass('opened');
        $('.wrapper').toggleClass('push');
      });
    });
  },

  Select2() {
    $(() => {
      $('select').each(function () {
        const $this = $(this);
        const options = {};
        const minWidth = parseInt(
          $this.css('min-width').replace(/px|em|ex|%|in|cm|mm|pt|pc/i, ''),
          10,
        );

        if (!$this.hasClass('select-search')) {
          options.minimumResultsForSearch = -1;
        }
        if ($this.hasClass('select-tags')) {
          options.tags = true;
        }
        if (minWidth === 0) {
          options.width = '100%';
        }
        $this.select2(options);
      });
    });
  },

  SlimScroll() {
    $.fn.slimscroll = function () {
      $(this).slimScroll({
        height: 'auto',
        color: '#c5c5c5',
        size: '4px',
      });
    };

    $('.slimscroll, .sidebar-content').slimscroll();
  },
};
