/* globals window document jQuery */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */

(function ($) {
  // Default Settings
  const _defaults = {
    // Background
    background: 'rgba(255, 255, 255, 0.75)',
    backgroundClass: '',
    // Font Awesome
    icon: 'fa fa-circle-o-notch fa-spin',
    iconAnimation: '',
    iconAutoResize: true,
    iconResizeFactor: 1,
    iconColor: '#2e2e2e',
    iconOrder: 2,
    // HTML
    html: '',
    // Sizing
    size: 35,
    maxSize: 40,
    minSize: 30,
    // Misc
    direction: 'column',
    fade: true,
    resizeInterval: 50,
    zIndex: 1049,
  };

  // Required CSS
  const _css = {
    overlay: {
      'box-sizing': 'border-box',
      position: 'fixed',
      display: 'flex',
      'flex-wrap': 'nowrap',
      'align-items': 'center',
      'justify-content': 'space-around',
    },
    element: {
      'box-sizing': 'border-box',
      overflow: 'visible',
      flex: '0 0 auto',
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
    },
  };

  // Data Template
  const _dataTemplate = {
    count: 0,
    container: undefined,
    settings: undefined,
    wholePage: undefined,
    resizeIntervalId: undefined,
  };

  function _IntervalResize(container, force) {
    const overlay = _GetOverlay(container);
    const data = _GetData(container);
    if (data === false) {
      return;
    }

    // Overlay
    if (!data.wholePage) {
      const pos = container[0].getBoundingClientRect();
      overlay.css({
        position: 'fixed',
        top: pos.top + parseInt(container.css('border-top-width'), 10),
        left: pos.left + parseInt(container.css('border-left-width'), 10),
        width: container.innerWidth(),
        height: container.innerHeight(),
      });
    }

    // Elements
    if (data.settings.size) {
      const c = data.wholePage ? $(window) : container;
      let size = data.settings.size.value;
      if (!data.settings.size.fixed) {
        size = (Math.min(c.innerWidth(), c.innerHeight()) * size) / 100;
        if (data.settings.maxSize && size > data.settings.maxSize) {
          size = data.settings.maxSize;
        }
        if (data.settings.minSize && size < data.settings.minSize) {
          size = data.settings.minSize;
        }
      }
      overlay.children('.loadingoverlay_element').each(function () {
        const $this = $(this);
        if (force || $this.data('loadingoverlay_autoresize')) {
          const resizeFactor = $this.data('loadingoverlay_resizefactor');
          $this.css('font-size', (size * resizeFactor) + data.settings.size.units);
          $this.find('.loadingoverlay_html').css('font-size', (size * resizeFactor * 0.75) + data.settings.size.units);
        }
      });
    }
  }

  function _GetOverlay(container) {
    return container.data('loadingoverlay');
  }

  function _GetData(container) {
    const overlay = _GetOverlay(container);
    const data = (typeof overlay === 'undefined') ? undefined : overlay.data('loadingoverlay_data');
    if (typeof data === 'undefined') {
      // Clean DOM
      $('.loadingoverlay').each(function () {
        const $this = $(this);
        const data = $this.data('loadingoverlay_data');
        if (!document.body.contains(data.container[0])) {
          if (data.resizeIntervalId) {
            clearInterval(data.resizeIntervalId);
          }
          $this.remove();
        }
      });
      return false;
    }
    overlay.toggle(container.is(':visible'));
    return data;
  }

  function _CreateElement(overlay, order, autoResize, resizeFactor) {
    const element = $('<div>', {
      class: 'loadingoverlay_element',
      css: {
        order,
      },
    }).css(_css.element).data({
      loadingoverlay_autoresize: autoResize,
      loadingoverlay_resizefactor: resizeFactor,
    }).appendTo(overlay);
    return element;
  }

  function _ParseSize(value) {
    if (!value || value < 0) {
      return false;
    } if (typeof value === 'string' && ['vmin', 'vmax'].indexOf(value.slice(-4)) > -1) {
      return {
        fixed: true,
        units: value.slice(-4),
        value: value.slice(0, -4),
      };
    } if (typeof value === 'string' && ['rem'].indexOf(value.slice(-3)) > -1) {
      return {
        fixed: true,
        units: value.slice(-3),
        value: value.slice(0, -3),
      };
    } if (typeof value === 'string' && ['px', 'em', 'cm', 'mm', 'in', 'pt', 'pc', 'vh', 'vw'].indexOf(value.slice(-2)) > -1) {
      return {
        fixed: true,
        units: value.slice(-2),
        value: value.slice(0, -2),
      };
    }
    return {
      fixed: false,
      units: 'px',
      value: parseFloat(value),
    };
  }

  function Show(container, settings) {
    container = $(container);
    settings.size = _ParseSize(settings.size);
    settings.maxSize = parseInt(settings.maxSize, 10) || 0;
    settings.minSize = parseInt(settings.minSize, 10) || 0;
    settings.resizeInterval = parseInt(settings.resizeInterval, 10) || 0;

    let overlay = _GetOverlay(container);
    let data = _GetData(container);

    if (data === false) {
      // Init data
      data = $.extend({}, _dataTemplate);
      data.container = container;
      data.wholePage = container.is('body');

      // Overlay
      overlay = $('<div>', {
        class: 'loadingoverlay',
      }).css(_css.overlay).css('flex-direction', settings.direction.toLowerCase() === 'row' ? 'row' : 'column');
      if (settings.backgroundClass) {
        overlay.addClass(settings.backgroundClass);
      } else {
        overlay.css('background', settings.background);
      }
      if (data.wholePage) {
        overlay.css({
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        });
      }
      if (typeof settings.zIndex !== 'undefined') {
        overlay.css('z-index', settings.zIndex);
      }

      const element = _CreateElement(
        overlay,
        settings.iconOrder,
        settings.iconAutoResize,
        settings.iconResizeFactor,
      ).css('flex-direction', settings.direction.toLowerCase() === 'row' ? 'row' : 'column');
      if (settings.iconColor) {
        element.css('color', settings.iconColor);
      }
      $('<div>', {
        class: settings.icon,
      }).appendTo(element);
      $('<div>', {
        class: 'loadingoverlay_html',
      }).appendTo(element);

      // Save settings
      data.settings = settings;
      // Save data
      overlay.data('loadingoverlay_data', data);
      // Save reference to overlay
      container.data('loadingoverlay', overlay);

      // Resize
      overlay.fadeTo(0, 0.01).appendTo('body');
      _IntervalResize(container, true);
      if (settings.resizeInterval > 0) {
        data.resizeIntervalId = setInterval(() => {
          _IntervalResize(container, false);
        }, settings.resizeInterval);
      }

      // Show LoadingOverlay
      overlay.fadeTo(settings.fade[0], 1);

      Html(container, settings.html);
    }

    data.count += 1;
  }

  function Hide(container, force) {
    container = $(container);
    const overlay = _GetOverlay(container);
    const data = _GetData(container);
    if (data === false) {
      return;
    }

    data.count -= 1;

    if (force || data.count <= 0) {
      overlay.animate({
        opacity: 0,
      }, data.settings.fade[1], function () {
        if (data.resizeIntervalId) {
          clearInterval(data.resizeIntervalId);
        }
        $(this).remove();
        container.removeData('loadingoverlay');
      });
    }
  }

  function Html(container, text) {
    const overlay = _GetOverlay($(container));
    overlay.find('.loadingoverlay_html').html(text);
  }

  function Resize(container) {
    _IntervalResize($(container), true);
  }

  $.loadingSetup = function (settings) {
    $.extend(true, _defaults, settings);
  };

  $.loading = function (action, options) {
    switch (action.toLowerCase()) {
      case 'show':
        if (typeof options === 'boolean') {
          options = {
            zIndex: 99999,
          };
        }
        Show('body', $.extend(true, {}, _defaults, options));
        break;

      case 'hide':
        Hide('body', options);
        break;

      case 'resize':
        Resize('body', options);
        break;

      case 'html':
        Html('body', options);
        break;

      default:
        break;
    }
  };

  $.fn.loading = function (action, options) {
    switch (action.toLowerCase()) {
      case 'show':
        if (typeof options === 'boolean') {
          options = {
            zIndex: 99999,
          };
        }
        return this.each(function () {
          Show(this, $.extend(true, {}, _defaults, options));
        });

      case 'hide':
        return this.each(function () {
          Hide(this, options);
        });

      case 'html':
        return this.each(function () {
          Html(this, options);
        });

      case 'resize':
        return this.each(function () {
          Resize(this, options);
        });
      default:
        break;
    }
  };
}(jQuery));
