/* globals JSONEditor */

var loadJsonEditor = function(callback) {
  if (typeof JSONEditor === 'undefined') {
    $('head').append('<link rel="stylesheet" media="all" href="https://cdnjs.cloudflare.com/ajax/libs/jsoneditor/7.0.4/jsoneditor.min.css" type="text/css">');
    $.getScript('https://cdnjs.cloudflare.com/ajax/libs/jsoneditor/7.0.4/jsoneditor.min.js', function() {
      callback();
    });
  } else {
    callback();
  }
};

$.fn.jsonEditor = function(json, options) {
  var container = $(this);
  var target = container.attr('data-editor-target');
  if (target) {
    target = $(target);
    loadJsonEditor(function() {
      var editor = new JSONEditor(container[0], {
        name: container.attr('data-editor-name') || 'Options',
        mode: container.attr('data-editor-mode') || 'tree',
        indentation: 4,
        onChangeText: function(json) {
          target.val(json);
        }
      });
      editor.setText(target.val());
      container.data('jsonEditor', editor);
    });
  } else {
    loadJsonEditor(function() {
      options = options || {};
      var editor = new JSONEditor(container[0], {
        name: options.name || 'Options',
        mode: options.mode || 'tree',
        indentation: 4,
        onChangeText: options.onchange
      });
      editor.set(json);
      container.data('jsonEditor', editor);
    });
  }
};

$(function() {
  $('.json-editor').each(function() {
    $(this).jsonEditor();
  });
});
