/* globals $ moment Cookies cordova */
/* globals cognito_pool_id cognito_client_id cognito_endpoint cognito_cookie_domain */
/* globals cognito_redirect_uri cognito_signout_uri */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import { Duxstrap } from './duxfront/duxstrap/duxstrap-core';
import { DuxstrapLite } from './duxfront/duxstrap/duxstrap-lite';
import { DuxstrapVideo } from './duxfront/duxstrap/duxstrap-video';
import { Duxdash } from './duxfront/duxdash/duxdash-core';
import { Cognito } from './duxfront/plugins/dux-cognito';
import {} from './utils';

Duxstrap.init();
DuxstrapLite.init();
DuxstrapVideo.init();
Duxdash.init();

// ****************** AWS COGNITO SESSION ******************

if (typeof cognito_pool_id !== 'undefined') {
  global.cognito = new Cognito(
    cognito_pool_id,
    cognito_client_id,
    cognito_endpoint,
    cognito_cookie_domain,
    cognito_redirect_uri,
    cognito_signout_uri,
  );

  $(() => {
    global.cognito.init();
  });
}

// ***************** CHECK CONNECTION *****************

$(() => {
  Duxdash.check_online('Sem conexão com a internet. Para continuar, conecte-se novamente.');
});

// ************ DATE RANGE PICKER CONFIG **************

global.drPickerConfig = function () {
  return {
    startDate: moment().startOf('minute').subtract(30, 'days'),
    endDate: moment().endOf('minute'),
    timePicker: true,
    timePicker24Hour: true,
    timePickerIncrement: 5,
    opens: 'center',
    locale: {
      format: 'DD/MM HH:mm:ss',
    },
  };
};

// *************** CORDOVA HELPERS *******************
$(() => {
  if (Cookies.get('mobileapp') !== undefined) {
    $('.external-link, .content-page a').click(function (e) {
      e.preventDefault();
      cordova.InAppBrowser.open($(this).attr('href'), '_system');
    });
  }
});
