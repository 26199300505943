/* global html2canvas */

var loadhtml2canvas = function(callback) {
  if (typeof html2canvas === 'undefined') {
    $.getScript('https://html2canvas.hertzen.com/dist/html2canvas.min.js', function() {
      callback();
    });
  } else {
    callback();
  }
};

$(function() {

  $.fn.toimage = function(filename, callback) {
    var $this = $(this);
    var filetype = filename.split('.');
    filetype = filetype[filetype.length - 1];
    loadhtml2canvas(function() {
      html2canvas($this.get(0)).then(function(canvas) {
        var data = null;
        var link = null;
        if (filetype === 'jpeg') {
          var data = canvas.toDataURL('image/jpeg').replace(/^data:image\/jpeg/, 'data:application/octet-stream');
        } else {
          var data = canvas.toDataURL('image/png').replace(/^data:image\/png/, 'data:application/octet-stream');
        }
        $('body').append('<a href="#" class="d-none" id="html2canvas-temp-download">dowload</a>');
        link = $('#html2canvas-temp-download');
        link.attr('href', data);
        link.attr('target', '_blank');
        link.attr('download', filename);
        link.get(0).click();
        link.remove();
        if (callback) {
          callback();
        }
      });
    });
  };

  $.fn.print = function(callback, onprint) {
    var item = $(this).get(0).outerHTML;
    var backup = $('body').get(0).outerHTML;
    $('body').html(item);
    if (onprint) {
      onprint();
    }
    $('body').loading('show');
    setTimeout(function() {
      $('body').loading('hide');
      setTimeout(function() {
        window.print();
        $('body').get(0).outerHTML = backup;
        if (callback) {
          callback();
        }
      }, 500);
    }, 2000);
  };

});
